require('./overrides.scss');

export default {
  theme: {
    themes: {
      light: {
        primary: '#005ea6',
        secondary: '#ac19d3',
        accent: '#83ba3c',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
      dark: {
        primary: '#005ea6',
        secondary: '#83ba3c',
        accent: '#ac19d3',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
    },
  },
};
